<template>
  <AuthLayout>
    <template #info>
      <InfoPanel
        title="One Account"
        subtitle="Unlocks all our products"
        finePrint
      />
    </template>

    <template #form>
      <div>
        <h3 class="text-sm mb-4 font-semibold">Email Verification</h3>
        <div v-if="loading">
          <p>Please wait while we verify your email...</p>
          <div class="w-full text-center">
            <ToznyLoader class="w-8 h-8 text-tozny" />
          </div>
        </div>
        <div v-if="displayError">
          <p class="text-error">
            {{ displayError }}
            <span v-if="!loggedIn"
              >Please login and resend the confirmation email.</span
            >
          </p>
        </div>
        <div v-if="emailIsVerified">
          <p>
            Your account has been verified.
            <span v-if="!loggedIn">Please login to continue.</span>
          </p>
        </div>
        <div v-if="!loading">
          <ToznyButton
            v-if="!loggedIn"
            @click.native="routeToLogin"
            class="mt-16 w-full py-4 rounded-lg"
            buttonText="Sign In"
            :loading="false"
          />
          <ToznyButton
            v-if="loggedIn"
            @click.native="routeToDashboard"
            class="mt-16 w-full py-4 rounded-lg"
            buttonText="Continue"
            :loading="false"
          />
        </div>
      </div>
    </template>
  </AuthLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AuthLayout from '@/Common/AuthLayout/AuthLayout'
import InfoPanel from '@/Common/AuthLayout/InfoPanel'
import ToznyButton from '@/Common/ToznyButton'
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'verification',
  components: {
    AuthLayout,
    InfoPanel,
    ToznyButton,
    ToznyLoader,
  },

  data: function () {
    return {
      loggedIn: false,
      emailIsVerified: false,
    }
  },
  computed: {
    ...mapState(['loading', 'displayError']),
  },
  methods: {
    ...mapActions(['pushRoute', 'setValidationError', 'toggleLoading']),
    ...mapActions('account', ['authenticateUser', 'verifyEmail']),
    routeToLogin() {
      this.pushRoute('/login')
    },
    routeToDashboard() {
      this.pushRoute('/')
    },
  },
  mounted: async function () {
    const params = this.$route.query
    /*
        Param toznyo is a one-time code generated by tozauth.
        Param toznyr is a realm name generated by tozauth.  
    */

    if (!params.id || !params.otp) {
      this.setValidationError('We were unable to verify your email.')
      return
    }
    const emailVerified = await this.verifyEmail({
      id: params.id,
      otp: params.otp,
    })
    this.emailIsVerified = emailVerified
    const userLoggedIn = await this.authenticateUser()
    this.loggedIn = userLoggedIn
  },
}
</script>
